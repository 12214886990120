import { parseHTML } from "./parse-html"

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText || response.status)
    error.response = response
    throw error
  }
}

function json(response) {
  return response.json()
}

function text(response) {
  return response.text()
}

function makeRequest(init, options) {
  const opts = options ? Object.assign({}, options) : {}

  if (!opts.credentials) {
    opts.credentials = "same-origin"
  }

  const request = new Request(init, opts)
  request.headers.append("X-Requested-With", "XMLHttpRequest")

  const csrfTokenElem = document.querySelector("meta[name='csrf-token']")
  if (csrfTokenElem instanceof HTMLMetaElement) {
    request.headers.append("X-CSRF-Token", csrfTokenElem.content)
  }

  return request
}

export function fetch(url, options) {
  const request = makeRequest(url, options)
  return self.fetch(request).then(checkStatus)
}

export function fetchText(url, options) {
  const request = makeRequest(url, options)
  return self
    .fetch(request)
    .then(checkStatus)
    .then(text)
}

export function fetchJSON(url, options) {
  const request = makeRequest(url, options)
  request.headers.set("Accept", "application/json")
  return self
    .fetch(request)
    .then(checkStatus)
    .then(json)
}

export function fetchForm(form) {
  return fetch(form.action, {
    method: form.method,
    body: new FormData(form),
  })
}

export async function fetchSafeDocumentFragment(document, url, options) {
  const request = makeRequest(url, options)
  const response = await self.fetch(request)
  checkStatus(response)
  return parseHTML(document, await response.text())
}
