<script setup lang="ts">
import { defineProps, computed, ref } from "vue"
import { usePlayerStore } from "~/stores/player-store"

const props = defineProps<{
  trackID: string
  versionUUID: string
  infoURL: string
  isBlurred: boolean
  isCurrent: boolean
}>()

const playerStore = usePlayerStore()
const isLoading = ref(false)

const backgroundClasses = []

// This bit here...
backgroundClasses.push(
  props.isBlurred
    ? "backdrop-blur-sm bg-blue-500/30"
    : props.isCurrent
    ? "bg-blue-500"
    : "bg-blue-100 dark:bg-blue-900"
)

if (props.isCurrent) {
  backgroundClasses.push("text-white")
} else {
  backgroundClasses.push("text-zinc-500/50")
}

async function toggle() {
  if (isPlaying.value) {
    playerStore.pause()
  } else {
    isLoading.value = true
    await playerStore.playFrom(props.infoURL)
    isLoading.value = false
  }
}

const isPlaying = computed(
  () => playerStore.isPlaying && playerStore.currentlyPlayingVersion.uuid === props.versionUUID
)
</script>

<template>
  <button
    type="button"
    class="shadow-md active:scale-95 transition-transform p-3 w-12 h-12 rounded-full flex align-center"
    :class="backgroundClasses"
    :data-id="trackID"
    :data-uuid="versionUUID"
    :data-info="infoURL"
    @click="toggle"
  >
    <svg
      v-if="isPlaying"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      class="bi bi-pause-fill pl-0.5"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
      />
    </svg>
    <svg
      v-else-if="isLoading"
      width="24"
      height="24"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
    >
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
          <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      class="bi bi-play-fill pl-0.5"
      viewBox="0 0 16 16"
    >
      <path
        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
      />
    </svg>
  </button>
</template>
