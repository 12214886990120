import { observe } from "selector-observer"

let tt = ""
let ftok = ""

observe('meta[name="tt"]', el => {
  tt = el.getAttribute("content")
})

observe('meta[name="ftok"]', el => {
  ftok = el.getAttribute("content")
})

export function graphql(query: string, variables: object) {
  return fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-TTOK": tt,
      "X-FTOK": ftok,
    },
    body: JSON.stringify({ query, variables }),
  }).then(res => res.json())
}
