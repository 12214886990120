import Cookie from "js-cookie"
import { watch, ref } from "vue"
import { TrackVersion } from "~/stores/player-store"

const audioElementsForVersions: { [uuid: string]: HTMLAudioElement } = {}

let volumeFromCookie = Cookie.get("volume")
export const volume = ref(volumeFromCookie ? Number(volumeFromCookie) : 100.0)
watch(volume, newValue => {
  Object.values(audioElementsForVersions).forEach(el => {
    el.volume = newValue / 100
  })
  Cookie.set("volume", newValue.toString(), { expires: 365 })
})

export function audioElementForVersion(version: TrackVersion, onend?: Function): HTMLAudioElement {
  const el = audioElementsForVersions[version.uuid]
  if (el) {
    return el
  }

  const newAudioEl = document.createElement("audio")
  newAudioEl.src = version.playUrl
  newAudioEl.preload = "auto"
  newAudioEl.volume = volume.value / 100
  newAudioEl.addEventListener("ended", () => {
    onend(newAudioEl)
  })

  audioElementsForVersions[version.uuid] = newAudioEl

  return newAudioEl
}

export function setMediaSessionInfo(version: TrackVersion) {
  if ("mediaSession" in navigator) {
    navigator.mediaSession.metadata = new window.MediaMetadata({
      title: version.track.name,
      artist: version.creator,
      album: `Version ${version.number}`,
      // artwork: [{ src: "podcast.jpg" }],
    })
  }
}
