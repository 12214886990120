/*       */

import invariant from "invariant"

export function parseHTML(document, html) {
  if (typeof HTMLTemplateElement.bootstrap !== "function") {
    const template = document.createElement("template")
    template.innerHTML = html
    return document.importNode(template.content, true)
  }

  // IE 11 doesn't support <template>
  // Fallback implementation has issues parsing table tag fragments
  const fragment = document.createDocumentFragment()
  // The IE 11 version of `createHTMLDocument` requires the `bstrTitle` argument
  // to be passed, otherwise it throws `TypeError: Argument not optional`:
  // https://msdn.microsoft.com/en-us/library/ff975457(v=vs.85).aspx
  const doc = document.implementation.createHTMLDocument(undefined)

  invariant(doc.body)
  doc.body.innerHTML = html

  invariant(doc.body.childNodes)
  const children = Array.from(doc.body.childNodes)

  for (const node of children) {
    fragment.appendChild(node)
  }

  // Decorate any <template> elements found in parsed HTML
  if (typeof HTMLTemplateElement.bootstrap === "function") {
    HTMLTemplateElement.bootstrap(fragment)
  }
  return fragment
}
