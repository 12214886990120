import { defineStore } from "pinia"
import { ref } from "vue"
import { fetch } from "~/utils/fetch"
import { volume, audioElementForVersion, setMediaSessionInfo } from "~/utils/audio"

// These types are what come back from the server
// TODO could maybe just use graphql instead of having a
// specific endpoint for this?
export type Track = {
  id: string
  name: string
  slug: string
}

export type TrackVersion = {
  id: string
  playUrl: string
  uuid: string
  duration: number
  track: Track
  number: number
  creator: string
  refreshUrl: string
}

const isPlaying = ref(false)
const versionsByInfoURL: { [url: string]: TrackVersion } = {}
const currentTime = ref(0)
const duration = ref(0)
const currentlyPlayingVersion = ref<TrackVersion | null>(null)

setInterval(() => {
  const version = currentlyPlayingVersion.value
  if (!version) {
    return
  }

  const el = audioElementForVersion(version, newAudioEl => {
    pause()
    newAudioEl.currentTime = 0
  })
  currentTime.value = el.currentTime
  duration.value = el.duration
  isPlaying.value = !el.paused
}, 200)

async function versionFromInfoURL(url: string): Promise<TrackVersion> {
  const version = versionsByInfoURL[url]
  if (version) {
    return version
  } else {
    const response = await fetch(url)
    const version: TrackVersion = await response.json()
    version.refreshUrl = url

    versionsByInfoURL[url] = version
    return version
  }
}

function playVersion(version: TrackVersion, sameTrack: boolean) {
  currentlyPlayingVersion.value = version
  play(sameTrack)
}

async function playFrom(url: string) {
  if (currentlyPlayingVersion.value === null || currentlyPlayingVersion.value.refreshUrl !== url) {
    const version = await versionFromInfoURL(url)
    pause()

    let sameTrack = false
    const currentVersion = currentlyPlayingVersion.value
    if (currentVersion) {
      sameTrack = currentVersion.track.id === version.track.id
    }

    playVersion(version, sameTrack)
  } else {
    play(false)
  }
}

async function play(sameTrack: boolean) {
  if (!currentlyPlayingVersion.value) {
    return
  }

  const el = audioElementForVersion(currentlyPlayingVersion.value)

  if (sameTrack) {
    el.currentTime = currentTime.value
  }
  el.play()
  isPlaying.value = true

  setMediaSessionInfo(currentlyPlayingVersion.value)

  if (el.currentTime === 0) {
    fetch("/_play/" + currentlyPlayingVersion.value.uuid, {
      method: "POST",
    })
  }
}

async function pause() {
  if (!currentlyPlayingVersion.value) {
    return
  }

  const el = audioElementForVersion(currentlyPlayingVersion.value)
  el.pause()

  isPlaying.value = false
}

function scrubTo(time: number) {
  if (!currentlyPlayingVersion.value) {
    return
  }

  const el = audioElementForVersion(currentlyPlayingVersion.value)
  el.currentTime = time
  currentTime.value = time
}

export const usePlayerStore = defineStore("player", {
  state: () => {
    return {
      isPlaying,
      currentlyPlayingVersion,
      volume,
      currentTime,
      duration,
    }
  },
  actions: {
    play,
    playFrom,
    pause,
    scrubTo,
  },
})

document.addEventListener("keypress", function(e: KeyboardEvent) {
  if (e.code != "Space") {
    return
  }

  if (!currentlyPlayingVersion.value) {
    return
  }

  const target = e.target as HTMLElement

  if (target.tagName == "INPUT" || target.tagName == "TEXTAREA" || target.tagName == "BUTTON") {
    return
  }

  if (isPlaying.value) {
    pause()
  } else {
    play(false)
  }

  e.preventDefault()
})
